import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "NDB オープンデータをオープン化してみた話",
  "date": "2017-08-17T08:00:00.000Z",
  "slug": "entry/2017/08/17/170000",
  "tags": ["medley"],
  "hero": "./2017_08_17.png",
  "heroAlt": "ndb"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`開発本部の平山です。先日、社内勉強会「TechLunch」にて社外に公開できない内容の発表をしてしまいましたので、その代わりとして、厚生労働省が提供する「NDB オープンデータ」をオープン化した話について、ブログを書こうと思います。`}{` `}</p>
    <h1>{`NDB オープンデータとは?`}</h1>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="第１回 NDB オープンデータ ｜厚生労働省" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.mhlw.go.jp%2Fstf%2Fseisakunitsuite%2Fbunya%2F0000139390.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation" style={{
      "fontStyle": "normal",
      "fontSize": "14.4px",
      "opacity": "0.75",
      "display": "block"
    }}><a href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/0000139390.html" style={{
        "color": "#999999",
        "opacity": "1",
        "fontSize": "11.52px"
      }}>www.mhlw.go.jp</a></cite>
    <blockquote>
      <p parentName="blockquote">{`作成の背景`}</p>
      <p parentName="blockquote">{`◆ レセプト情報・特定健診等情報データベース（NDB）は、悉皆性が高いレセプト情報、および検査値などの詳細な情報を有する特定健診等情報が含まれており、国民の医療動向を評価するうえで有用なデータだと考えられている。`}</p>
      <p parentName="blockquote">{`◆ 2011 年度より、医療費適正化計画策定に資する目的以外での NDB データの利用が認められたが、NDB データの機微性の高さに鑑み、利用者に対しては高いレベルのセキュリティ要件を課したうえで、データ提供が行われてきた。`}</p>
      <p parentName="blockquote">{`◆ 一方で、多くの研究者が必ずしも詳細な個票データを必要とするわけではないため、多くの人々が使用できるような、あらかじめ定式化された集計データを NDB データをもとに整備することが重要ではないか、という議論が有識者会議等でなされてきた。`}</p>
      <p parentName="blockquote">{`◆ NDB の民間提供に関する議論でも、「レセプト情報等の提供に関するワーキンググループ」からの報告では、汎用性が高く様々なニーズに一定程度応えうる基礎的な集計表を作成し、公表していくことがむしろ適当である、という指摘がみられた。`}</p>
      <p parentName="blockquote">{`作成の目的`}</p>
      <p parentName="blockquote">{`◆ 多くの人々が NDB データに基づいた保健医療に関する知見に接することが出来るよう、NDB データを用いて基礎的な集計表を作成したうえで、公表する。`}</p>
      <p parentName="blockquote">{`◆ NDB データに基づき、医療の提供実態や特定健診等の結果をわかりやすく示す。`}</p>
    </blockquote>
    <p>{`要は皆さんが、病院に行った時にもらう明細書に記載されている初診〇〇点、外来診療料〇〇点のようなデータが個人情報が匿名化された状態で収集しその統計データを一般に公開する、といったところでしょうか。`}</p>
    <p>{`このようなデータがオープンになっていることはとても意義のあることだと思いますし、公開にまでこぎつけた関係者の苦労が想像されます。しかし、このような画期的なデータ提供ではありますが、Excel ファイルでの提供となっており、かつ加工がしづらいデータ構造になっているため、データを細かくみてみようとすると非常に手間がかかるという問題があります。`}</p>
    <h1>{`NDB オープンデータのオープン化`}</h1>
    <p>{`そこで NDB オープンデータとして公開されている Excel ファイルを加工し、DB に格納し BI ツール（`}<a parentName="p" {...{
        "href": "https://redash.io/"
      }}>{`Redash`}</a>{`）から参照させるようにしてみました。`}</p>
    <h2>{`1. データ加工 & DB 取り込み`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/0000139390.html"
      }}>{`公開サイト`}</a>{`にある医科診療行為に関する Excel ファイルを取得し、ログテーブルとしてよくあるフォーマットに変換し DB に取り込む。`}</p>
    <h3>{`変換前`}</h3>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170721/20170721150757.png",
        "alt": "f:id:medley_inc:20170721150757p:plain",
        "title": "f:id:medley_inc:20170721150757p:plain"
      }}></img></p>
    <h3>{`変換後`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`*************************** 1. row ***************************
id: 1
practice_category_code: A000
practice_category_name: 初診料
practice_code: 111000110
practice_name: 初診
practice_type: 外来
target: all
revision: 2014
prefecture:
sex:
age:
score: 251700771
created_at: 0000-00-00 00:00:00
updated_at: 0000-00-00 00:00:00
*************************** 2. row ***************************
id: 2
practice_category_code: A000
practice_category_name: 初診料
practice_code: 111000110
practice_name: 初診
practice_type: 外来
target: sex_age
revision: 2014
prefecture:
sex: 男性
age: 0～4 歳
score: 13158090
created_at: 0000-00-00 00:00:00
updated_at: 0000-00-00 00:00:00
*************************** 3. row ***************************
id: 3
practice_category_code: A000
practice_category_name: 初診料
practice_code: 111000110
practice_name: 初診
practice_type: 外来
target: sex_age
revision: 2014
prefecture:
sex: 男性
age: 5～9 歳
score: 12444947
created_at: 0000-00-00 00:00:00
updated_at: 0000-00-00 00:00:00`}</code></pre></div>
    <h2>{`2. データの参照`}</h2>
    <p>{`変換したデータを取り込んだ DB を Redash から参照。分析したいデータを取得するためのクエリを書いてダッシュボード化。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170721/20170721153210.png",
        "alt": "f:id:medley_inc:20170721153210p:plain",
        "title": "f:id:medley_inc:20170721153210p:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170726/20170726125246.png",
        "alt": "f:id:medley_inc:20170726125246p:plain",
        "title": "f:id:medley_inc:20170726125246p:plain"
      }}></img></p>
    <h3>{`NDB オープンデータの活用例`}</h3>
    <p>{`以下に簡単なデータ活用のサンプルを載せました。医薬診療行為だけでなく特定健診や薬剤のデータを使うともう少し面白い気付きがあるかもしれません。`}</p>
    <p>{`いずれにせよ、このように加工可能な形でのデータ提供こそがオープンデータ提供の価値だと思うので、このような仕組みが加速すれば良いなと思います。`}</p>
    <h4>{`0-4 歳 男性 診療行為点数`}</h4>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170817/20170817101900.png",
        "alt": "f:id:dev-medley:20170817101900p:plain",
        "title": "f:id:dev-medley:20170817101900p:plain"
      }}></img></p>
    <h4>{`90 歳以上 男性 診療行為点数`}</h4>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170817/20170817101934.png",
        "alt": "f:id:dev-medley:20170817101934p:plain",
        "title": "f:id:dev-medley:20170817101934p:plain"
      }}></img></p>
    <h4>{`140023350 胃瘻より流動食点滴注入 都道府県別`}</h4>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170817/20170817102021.png",
        "alt": "f:id:dev-medley:20170817102021p:plain",
        "title": "f:id:dev-medley:20170817102021p:plain"
      }}></img></p>
    <h4>{`150086210 角膜移植術 年齡別`}</h4>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170817/20170817102100.png",
        "alt": "f:id:dev-medley:20170817102100p:plain",
        "title": "f:id:dev-medley:20170817102100p:plain"
      }}></img></p>
    <h1>{`まとめ`}</h1>
    <p>{`以上、NDB オープンデータをオープン化してみた話について書いてみました。`}</p>
    <p>{`このように.go.jp から提供されるデータは一般的に Excel や PDF でのファイル提供が基本で、インターネットサービスのように API のような形で提供されることはありません。せっかく貴重なデータが提供されているにも関わらず、それが IT システムと連動しづらいことで、活用されない状況になっているのはとても残念なことに思います。`}<a parentName="p" {...{
        "href": "https://www.codeforamerica.org/"
      }}>{`Code for America`}</a>{`の事例ではないですが、もっとインターネット系の人材がこのような取り組みに入り込んでいくようになれば、より合理的でスマートな仕組みが加速し、業界全体の IT 化も加速するのではないでしょうか。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」、オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」、医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」、口コミで探せる介護施設の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p>{`メドレーで一緒に医療体験を変えるプロダクト作りに関わりたい方のご連絡お待ちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      